import * as React from 'react'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'
import ContactForm from '../components/contactForm'
import DateFormatter from '../components/date-formatter'
import Seo from '../components/seo'

import {
  projectBody,
  inFormComponent,
} from '../components/layout.module.scss'

const Posts = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.webiny.listPosts.data
  
  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="Posts" />
        <div className={projectBody}>
          <div className='grid grid-cols-1 lg:grid-cols-12 gap-5'>
            <div className='lg:col-span-8'>  
            <p>
              No blog posts found. Add markdown posts to "content/blog" (or the
              directory you specified for the "gatsby-source-filesystem" plugin in
              gatsby-config.js).
            </p>
          </div>
          <div className='lg:col-span-4'>
              <div className="col-span-4">
                  <div className="float-right sticky top-24 inline-block">
                      <ContactForm />
                  </div>
              </div>
          </div>
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout location={location} pageTitle="Blog" title={siteTitle}>
    <Seo
      title="Web Design, UI/UX, Branding, and App Development Blog"
      description="BRADIENT Blog offers great articles on web design, UI/UX design, app design, development, and design mockups. Discover the latest design trends and techniques." 
    />
      <div className={projectBody}>
        <div className='grid grid-cols-1 lg:grid-cols-12 gap-5'>
          <div className='lg:col-span-8'>  
          <p>BRADIENT Blog offers great articles on web design, UI/UX design, app design, development, and design mockups. Discover the latest design trends and techniques.</p>
          <hr className=' !mb-16 !border-b-gray-200' />
    
              {posts.map(post => {
              return (
                <div 
                  key={post.slug} 
                  className="py-10 border-b first-of-type:!pt-0 last-of-type:border-none"
                >
                  <article
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <div className='grid grid-cols-1 lg:grid-cols-6 lg:gap-5'>

                      <div className='col-span-1 lg:col-span-2'>
                        <Link to={post.slug} itemProp="url" className='text-black'>

                          <img src={post.featuredImage} className='min-w-full mb-4 lg:mb-0 min-h-[140px] object-cover h-14 max-h-14 rounded-lg border border-slate-100 inline-block shadow-md shadow-slate-300/25' alt={post.title} title={post.title} />

                        </Link>
                      </div>
                      
                      <div className='col-span-2 lg:col-span-4'>
                        <header className='mb-2'>
                          <Link to={post.slug} itemProp="url" className='text-black'>
                            <h2 className='text-xl text-black font-semibold mb-0'>
                                <span itemProp="headline">{post.title}</span>
                            </h2>
                          </Link>
                            <span className="inline-block w-full text-xs"><DateFormatter dateString={post.datePublished} /></span>
                        </header>
                        <section>
                          <p>{post.description}</p>
                          <Link to={post.slug} itemProp="url" className='text-gray-600 underline text-xs'>Read more</Link>
                        </section>
                      </div>

                    </div>
                  </article>
                </div>
              )
              })}
              <br />
          </div>
          <div className='lg:col-span-4'>
            <div className={inFormComponent}>
              <div className="float-right sticky top-24 inline-block">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Posts

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    webiny {
      listPosts(sort: datePublished_DESC, limit: 30) {
        data {
            id
            title
            slug
            description
            createdOn
            datePublished
            featuredImage
          }
        }
      }
    }
`